import React, { useState, useCallback, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import QueryInput from './QueryInput';
import Card from '../Card';
import { useAuth } from '../../contexts/AuthContext';
import QueryResultCard from './QueryResultCard';
import SaveQueryModal from './SaveQueryModal';
import AddToDashboardModal from './AddToDashboardModal';
import QueryError from './QueryError';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch() {
    console.error('An error occurred in the application.');
  }

  render() {
    if (this.state.hasError) {
      return (
        <Card className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <h1>Something went wrong.</h1>
        </Card>
      );
    }

    return this.props.children;
  }
}

const QueryComponent = forwardRef(
  (
    {
      mainContentRef,
      onQueryResult,
      onQueryStart,
      queryResult,
      isLoading,
      onSaveQuery,
      onAddToDashboard,
      onFollowUpQuery,
      onDashboardUpdate,
      dashboardManager,
      isQueryJustAdded,
      setIsQueryJustAdded,
    },
    ref
  ) => {
    const componentRef = useRef(null);
    const [error, setError] = useState(null);
    const [isSaveQueryModalOpen, setIsSaveQueryModalOpen] = useState(false);
    const [isAddToDashboardModalOpen, setIsAddToDashboardModalOpen] = useState(false);
    const [queryName, setQueryName] = useState('');
    const [isShared, setIsShared] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [savedQueryId, setSavedQueryId] = useState(null);
    const [isExplanationLoading, setIsExplanationLoading] = useState(false);
    const [explanation, setExplanation] = useState('');
    const [showResultCard, setShowResultCard] = useState(false);
    const [truncationMessage, setTruncationMessage] = useState('');
    const [isFollowUp, setIsFollowUp] = useState(false);
    const [dashboards, setDashboards] = useState([]);

    const resultRef = React.useRef(null);
    const { currentUser, isAdmin } = useAuth();

    useImperativeHandle(ref, () => ({
      scrollIntoView: () => {
        if (componentRef.current) {
          componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      },
    }));

    useEffect(() => {
      fetchDashboards();
    }, []);

    const fetchDashboards = async () => {
      try {
        const response = await fetch(
          'https://us-central1-plazit.cloudfunctions.net/handleDashboardOperation',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data: {
                operation: 'get',
                userId: currentUser.email,
                isAdmin: isAdmin,
              },
            }),
          }
        );
        const data = await response.json();
        setDashboards(data);
      } catch (error) {
        console.error('Error fetching dashboards:', error);
        setError('Failed to fetch dashboards. Please try again later.');
      }
    };

    const generateExplanation = async (sqlQuery, documentId, savedQueryId) => {
      setIsExplanationLoading(true);
      try {
        const requestData = { sqlQuery, documentId, savedQueryId };

        if (!requestData.documentId && !requestData.savedQueryId) {
          throw new Error('Missing necessary identifiers.');
        }

        const response = await fetch(
          'https://us-central1-plazit.cloudfunctions.net/generateExplanation',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: requestData }),
          }
        );
        if (!response.ok) {
          throw new Error('Failed to generate explanation.');
        }
        const data = await response.json();
        return data.explanation;
      } catch (error) {
        console.error('Error generating explanation:', error);
        throw error;
      } finally {
        setIsExplanationLoading(false);
      }
    };

    const handleSubmit = useCallback(
      async (submittedQuery, isFollowUp = false, highlightedText = '', originalSqlQuery = '') => {
        console.log('Submitting query:', { submittedQuery, isFollowUp, highlightedText, originalSqlQuery });
        onQueryStart();
        setError(null);
        setShowResultCard(false);
        setTruncationMessage('');
        setIsQueryJustAdded(false);
        let responseData = null;
        try {
          const controller = new AbortController();
          const timeoutId = setTimeout(() => controller.abort(), 120000);
    
          const userId = currentUser?.email || 'anonymous';
    
          const response = await fetch(
            'https://us-central1-plazit.cloudfunctions.net/processDynamicQueryWithCORS',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                data: {
                  userQuery: submittedQuery,
                  userId: userId,
                  isFollowUp: isFollowUp,
                  highlightedText: highlightedText,
                  originalDocumentId: isFollowUp ? queryResult?.documentId : null,
                  originalSavedQueryId: isFollowUp ? queryResult?.savedQueryId : null,
                  originalSqlQuery: isFollowUp ? originalSqlQuery : null,
                },
                context: { auth: true },
              }),
              signal: controller.signal,
            }
          );
    
          clearTimeout(timeoutId);
    
          responseData = await response.json();
    
          if (!response.ok || responseData.error) {
            throw new Error('Failed to process query.');
          }
    
          const truncationMessage = responseData.truncated
            ? 'The query result has been limited to 100 rows. Consider refining your query if you need more specific data.'
            : '';
    
          setTruncationMessage(truncationMessage);
    
          let autoExplanation = '';
          try {
            autoExplanation = await generateExplanation(
              responseData.sqlQuery,
              responseData.documentId,
              responseData.savedQueryId
            );
          } catch {
            // Continue without explanation
          }
    
          const newResult = {
            ...responseData,
            userQuery: submittedQuery,
            isFollowUp: isFollowUp,
            isSavedQuery: responseData.isSavedQuery || false,
            name: responseData.name || '',
            config: {
              usedModel: responseData.usedModel || 'Unknown',
              fallbackUsed: responseData.fallbackUsed || false,
            },
            retryCount: responseData.retryCount || 0,
            retryResult: responseData.retryResult || 'N/A',
            bigQueryError: responseData.bigQueryError || null,
            result: responseData.result,
            truncationMessage: truncationMessage,
            explanation: autoExplanation || responseData.explanation || '',
            documentId:
              responseData.documentId ||
              (isFollowUp ? queryResult?.documentId : null),
            sqlQuery: responseData.sqlQuery || queryResult?.sqlQuery,
            savedQueryId: responseData.savedQueryId || queryResult?.savedQueryId,
            truncated: responseData.truncated || false,
            schema:
              responseData.schema ||
              {
                fields:
                  responseData.result && responseData.result.length > 0
                    ? Object.keys(responseData.result[0]).map((key) => ({
                        name: key,
                        type:
                          typeof responseData.result[0][key] === 'number'
                            ? 'FLOAT'
                            : 'STRING',
                      }))
                    : [],
              },
            emptyResultSet: responseData.result && responseData.result.length === 0,
            status: responseData.status || 'completed',
            endTime: responseData.endTime || new Date().toISOString(),
          };
    
          onQueryResult(newResult);
          setShowResultCard(true);
          setIsFollowUp(false);
    
          setTimeout(() => {
            if (mainContentRef && mainContentRef.current) {
              mainContentRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }
          }, 100);
        } catch (err) {
          console.error('Error during query submission:', err);
          const errorObject = {
            message:
              err.name === 'AbortError'
                ? 'The request took too long to respond. Please try again or simplify your query.'
                : 'An unexpected error occurred. Please try again.',
            status: err.name === 'AbortError' ? 'Timeout' : 'Error',
          };
    
          setError(errorObject);
          onQueryResult(null);
        }
      },
      [onQueryResult, onQueryStart, currentUser, queryResult, mainContentRef, generateExplanation]
    );

    const handleFollowUpQuery = useCallback(
      async (followUpQuery, highlightedText, originalSqlQuery) => {
        console.log('Handling follow-up query:', { followUpQuery, highlightedText, originalSqlQuery });
        await handleSubmit(followUpQuery, true, highlightedText, originalSqlQuery);
    
        setTimeout(() => {
          if (componentRef.current) {
            componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
      },
      [handleSubmit, componentRef]
    );
    
    const handleQueryInputSubmit = useCallback(
      (query, isFollowUpMode, selectedText) => {
        if (isFollowUpMode) {
          handleFollowUpQuery(query, selectedText, queryResult?.sqlQuery);
        } else {
          handleSubmit(query);
        }
      },
      [handleFollowUpQuery, handleSubmit, queryResult]
    );

    const handleSaveQuery = async () => {
      if (!queryResult) return;
      setIsSaveQueryModalOpen(true);
    };

    const handleSaveQuerySubmit = async () => {
      if (!queryName || isSaving) return;
    
      setIsSaving(true);
      try {
        const response = await fetch(
          'https://us-central1-plazit.cloudfunctions.net/saveQuery',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data: {
                userId: currentUser.email,
                name: queryName,
                userQuery: queryResult.userQuery,
                sqlQuery: queryResult.sqlQuery,
                llmModel: queryResult.config.usedModel,
                fallbackUsed: queryResult.config.fallbackUsed,
                originalModel: queryResult.originalModel,
                documentId: queryResult.documentId,
                isPrivate: !isShared,
                explanation: explanation,
                schema: queryResult.schema,
              },
            }),
          }
        );
        const { id } = await response.json();
        setSavedQueryId(id);
        setIsSaveQueryModalOpen(false);
        setIsQueryJustAdded(false);  // Reset isQueryJustAdded before opening the AddToDashboardModal
        setIsAddToDashboardModalOpen(true);
        if (onSaveQuery) {
          onSaveQuery();
        }
      } catch (error) {
        console.error('Error saving query:', error);
        setError('Failed to save query. Please try again.');
      } finally {
        setIsSaving(false);
      }
    };

    const handleAddToDashboard = async (dashboardId) => {
      try {
        const response = await fetch(
          'https://us-central1-plazit.cloudfunctions.net/handleDashboardOperation',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data: {
                operation: 'addQueryToDashboard',
                reportData: {
                  dashboardId: dashboardId,
                  query: {
                    id: savedQueryId,
                    name: queryName,
                    userQuery: queryResult.userQuery,
                    sqlQuery: queryResult.sqlQuery,
                    result: queryResult.result,
                  },
                },
                userId: currentUser.email,
              },
            }),
          }
        );
        if (!response.ok) {
          throw new Error('Failed to add query to dashboard.');
        }
        const updatedDashboard = await response.json();
        setIsQueryJustAdded(true);
        if (onDashboardUpdate) {
          onDashboardUpdate(updatedDashboard);
        }
        if (dashboardManager && dashboardManager.handleSelectDashboard) {
          await dashboardManager.handleSelectDashboard(updatedDashboard);
        }
        setIsAddToDashboardModalOpen(false);
      } catch (error) {
        console.error('Error adding query to dashboard:', error);
        setError('Failed to add query to dashboard. Please try again.');
      }
    };

    const handleCreateNewDashboard = async (dashboardName) => {
      try {
        const response = await fetch(
          'https://us-central1-plazit.cloudfunctions.net/handleDashboardOperation',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data: {
                operation: 'create',
                reportData: {
                  name: dashboardName,
                  queries: [savedQueryId],
                },
                userId: currentUser.email,
              },
            }),
          }
        );
        if (!response.ok) {
          throw new Error('Failed to create new dashboard.');
        }
        const newDashboard = await response.json();
        if (!newDashboard || !newDashboard.id) {
          throw new Error('Invalid dashboard data received from server.');
        }
        setIsQueryJustAdded(true);
        if (onDashboardUpdate) {
          onDashboardUpdate(newDashboard);
        }
        setIsAddToDashboardModalOpen(false);
        return newDashboard;
      } catch (error) {
        console.error('Error creating new dashboard:', error);
        setError('Failed to create new dashboard. Please try again.');
        return null;
      }
    };

    const handleExplanationClick = async () => {
      if (explanation) return;
      try {
        const newExplanation = await generateExplanation(
          queryResult.sqlQuery,
          queryResult.documentId,
          queryResult.savedQueryId
        );
        setExplanation(newExplanation);
        onQueryResult({
          ...queryResult,
          explanation: newExplanation,
        });
      } catch (error) {
        console.error('Error generating explanation:', error);
        setError('Failed to generate explanation. Please try again.');
      }
    };

    useEffect(() => {
      if (queryResult && queryResult.result) {
        setShowResultCard(true);
        setExplanation(queryResult.explanation || '');
        setTruncationMessage(queryResult.truncationMessage || '');
      }
    }, [queryResult]);

    return (
      <ErrorBoundary>
        <div ref={componentRef}>
          <QueryInput onSubmit={handleQueryInputSubmit} isLoading={isLoading} isFollowUp={isFollowUp} />
          {error && <QueryError error={error} onClose={() => setError(null)} />}
          {queryResult && showResultCard && (
            <div ref={resultRef}>
              <QueryResultCard
                query={queryResult}
                currentUser={currentUser}
                onSaveQuery={handleSaveQuery}
                onExplanationClick={handleExplanationClick}
                isExplanationLoading={isExplanationLoading}
                truncationMessage={truncationMessage}
                onFollowUpQuery={handleFollowUpQuery}
                isDashboardMode={false}
              />
            </div>
          )}
          <SaveQueryModal
            isOpen={isSaveQueryModalOpen}
            onClose={() => setIsSaveQueryModalOpen(false)}
            onSubmit={handleSaveQuerySubmit}
            queryName={queryName}
            setQueryName={setQueryName}
            isShared={isShared}
            setIsShared={setIsShared}
            isSaving={isSaving}
          />
          <AddToDashboardModal
            isOpen={isAddToDashboardModalOpen}
            onClose={() => {
              setIsAddToDashboardModalOpen(false);
              setIsQueryJustAdded(false);
            }}
            dashboards={dashboards}
            onAddToDashboard={handleAddToDashboard}
            onCreateNewDashboard={handleCreateNewDashboard}
            onDashboardUpdate={onDashboardUpdate}
            isQueryJustAdded={isQueryJustAdded}
            fetchDashboards={fetchDashboards}
          />
        </div>
      </ErrorBoundary>
    );
  }
);

export default QueryComponent;