import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useDashboard } from '../contexts/DashboardContext';
import DashboardList from '../components/Dashboards/DashboardList';
import DashboardContent from '../components/Dashboards/DashboardContent';
import DashboardModals from '../components/Dashboards/DashboardModals';
import QuerySection from '../components/Dashboards/QuerySection';
import DashboardManager from '../components/Dashboards/DashboardManager';

const Home = ({ mainContentRef }) => {
  const [dashboards, setDashboards] = useState([]);
  const [isNewDashboardModalOpen, setIsNewDashboardModalOpen] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState('');
  const [selectedQueries, setSelectedQueries] = useState([]);
  const [queryResult, setQueryResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDashboardLoading, setIsDashboardLoading] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser, isAdmin } = useAuth();
  const { selectedDashboard, updateSelectedDashboard } = useDashboard();
  const savedQueriesRef = useRef();
  const [isAddToDashboardModalOpen, setIsAddToDashboardModalOpen] = useState(false);
  const [queryToAdd, setQueryToAdd] = useState(null);
  const [isEditDashboardModalOpen, setIsEditDashboardModalOpen] = useState(false);
  const [availableQueries, setAvailableQueries] = useState([]);
  const [authChecked, setAuthChecked] = useState(false);
  const [isQueryJustAdded, setIsQueryJustAdded] = useState(false);

  const [userPreferences, setUserPreferences] = useState(() => {
    const savedPreferences = localStorage.getItem('dashboardPreferences');
    return savedPreferences ? JSON.parse(savedPreferences) : {};
  });

  const handleQueryResult = useCallback((result) => {
    setQueryResult(result);
    setIsLoading(false);
  }, []);

  const handleQueryStart = useCallback(() => {
    setIsLoading(true);
    setQueryResult(null);
  }, []);

  const submitQuery = useCallback(
    async (query, isFollowUp = false, highlightedText = '', originalSqlQuery = '') => {
      handleQueryStart();
      try {
        const userId = currentUser?.email || 'anonymous';
        const userQuery = query;

        const response = await fetch(
          'https://us-central1-plazit.cloudfunctions.net/processDynamicQueryWithCORS',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data: {
                userQuery: userQuery,
                userId: userId,
                isFollowUp: isFollowUp,
                isAdmin: isAdmin,
                highlightedText: highlightedText,
                originalSqlQuery: originalSqlQuery,
                originalDocumentId: queryResult ? queryResult.documentId : null,
                originalSavedQueryId: queryResult ? queryResult.savedQueryId : null,
              },
              context: { auth: true },
            }),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error('Failed to process query.');
        }

        const {
          result = [],
          sqlQuery = '',
          schema = '',
          userQuery: responseUserQuery = query,
          truncationMessage = '',
          truncated = false,
          explanation = '',
          isFollowUp: responseIsFollowUp = isFollowUp,
          originalQuery = '',
          documentId = queryResult ? queryResult.documentId : null,
          savedQueryId = queryResult ? queryResult.savedQueryId : null,
          retryCount = 0,
          retryResult = 'N/A',
          bigQueryError = null,
          status = 'completed',
          endTime = new Date().toISOString(),
        } = data;

        const newResult = {
          result,
          sqlQuery,
          schema,
          userQuery: responseUserQuery,
          truncationMessage,
          truncated,
          explanation,
          isFollowUp: responseIsFollowUp,
          originalQuery,
          documentId,
          savedQueryId,
          config: {
            usedModel: data.usedModel || 'Unknown',
            fallbackUsed: data.fallbackUsed || false,
          },
          retryCount,
          retryResult,
          bigQueryError,
          status,
          endTime,
        };

        handleQueryResult(newResult);

        setIsLoading(false);

        setTimeout(() => {
          if (mainContentRef && mainContentRef.current) {
            mainContentRef.current.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        }, 100);
      } catch (error) {
        console.error('Error submitting query:', error);
        setError({
          message: 'An unexpected error occurred. Please try again.',
          status: 'error',
        });
        setIsLoading(false);
        handleQueryResult(null);
      }
    },
    [currentUser, handleQueryStart, handleQueryResult, queryResult, mainContentRef, setIsLoading, setError, isAdmin]
  );

  const handleFollowUpQuery = useCallback(
    (followUpQuery, highlightedText, originalSqlQuery) => {
      submitQuery(followUpQuery, true, highlightedText, originalSqlQuery);

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      if (mainContentRef && mainContentRef.current) {
        mainContentRef.current.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    [submitQuery, mainContentRef]
  );

  const dashboardManager = DashboardManager({
    dashboards,
    setDashboards,
    setError,
    userPreferences,
    setUserPreferences,
    handleQueryResult,
    setIsDashboardLoading,
    currentUser,
    isAdmin,
  });

  useEffect(() => {
    if (currentUser !== null) {
      setAuthChecked(true);
    }
  }, [currentUser]);

  const fetchAvailableQueries = useCallback(async () => {
    if (!currentUser) return;
    try {
      const response = await fetch(
        'https://us-central1-plazit.cloudfunctions.net/getSavedQueries',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: {
              userId: currentUser.email,
            },
          }),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch available queries.');
      }
      const allQueries = await response.json();
      setAvailableQueries(allQueries);
    } catch (error) {
      console.error('Error fetching available queries:', error);
      setError('Failed to fetch available queries. Please try again later.');
    }
  }, [currentUser, setError]);

  useEffect(() => {
    fetchAvailableQueries();
  }, [fetchAvailableQueries]);

  const filterAvailableQueries = useCallback(
    (dashboardQueries) => {
      const dashboardQueryIds = dashboardQueries.map((q) => q.id);
      return availableQueries.filter((q) => !dashboardQueryIds.includes(q.id));
    },
    [availableQueries]
  );

  const handleNewDashboard = () => {
    if (!currentUser) {
      setError('Please log in to perform this action.');
      return;
    }
    setIsNewDashboardModalOpen(true);
  };

  const handleCreateDashboard = async () => {
    const newDashboard = await dashboardManager.handleCreateDashboard(
      newDashboardName,
      selectedQueries
    );
    if (newDashboard) {
      setIsNewDashboardModalOpen(false);
      setNewDashboardName('');
      setSelectedQueries([]);
      setDashboards((prevDashboards) => [...prevDashboards, newDashboard]);
      dashboardManager.handleSelectDashboard(newDashboard);
    }
  };

  const handleSaveQuery = useCallback(() => {
    if (savedQueriesRef.current) {
      savedQueriesRef.current.refreshQueries();
    }
    fetchAvailableQueries();
  }, [fetchAvailableQueries]);

  const handleExplanationClick = async (query) => {
    try {
      const response = await fetch(
        'https://us-central1-plazit.cloudfunctions.net/generateExplanation',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: {
              sqlQuery: query.sqlQuery,
              savedQueryId: query.id,
            },
          }),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to generate explanation.');
      }
      const data = await response.json();
      const updatedQueries = selectedDashboard.queries.map((q) =>
        q.id === query.id ? { ...q, explanation: data.explanation } : q
      );
      updateSelectedDashboard({ ...selectedDashboard, queries: updatedQueries });
    } catch (error) {
      console.error('Error generating explanation:', error);
      setError('Failed to generate explanation. Please try again.');
    }
  };

  const handleSaveCardSettings = useCallback(
    (queryId, settings) => {
      if (selectedDashboard) {
        const updatedQueries = selectedDashboard.queries.map((q) =>
          q.id === queryId
            ? { ...q, cardSettings: { ...q.cardSettings, ...settings } }
            : q
        );
        const updatedDashboard = { ...selectedDashboard, queries: updatedQueries };
        updateSelectedDashboard(updatedDashboard);

        dashboardManager.saveUserPreferences(selectedDashboard.id, {
          ...userPreferences[selectedDashboard.id],
          querySettings: {
            ...(userPreferences[selectedDashboard.id]?.querySettings || {}),
            [queryId]: {
              ...(userPreferences[selectedDashboard.id]?.querySettings?.[queryId] || {}),
              ...settings,
            },
          },
        });

        setDashboards((prevDashboards) => {
          const updatedDashboards = prevDashboards.map((d) =>
            d.id === selectedDashboard.id ? updatedDashboard : d
          );
          return [...updatedDashboards];
        });
      }
    },
    [selectedDashboard, updateSelectedDashboard, userPreferences, dashboardManager]
  );

  const handleAddToDashboard = useCallback((query) => {
    setQueryToAdd(query);
    setIsAddToDashboardModalOpen(true);
    setIsQueryJustAdded(false);
  }, []);

  const handleAddQueryToDashboard = async (dashboardId) => {
    const success = await dashboardManager.handleAddQueryToDashboard(
      dashboardId,
      queryToAdd
    );
    if (success) {
      setIsQueryJustAdded(true);
    }
  };

  const handleEditDashboard = (dashboard) => {
    updateSelectedDashboard(dashboard);
    setIsEditDashboardModalOpen(true);
  };

  const handleUpdateDashboard = async (updatedDashboard) => {
    const success = await dashboardManager.handleUpdateDashboard(updatedDashboard);
    if (success) {
      setIsEditDashboardModalOpen(false);
    }
  };

  const handleDashboardUpdate = useCallback(
    (updatedDashboard) => {
      if (!updatedDashboard || !updatedDashboard.id) {
        console.error('Invalid updated dashboard.');
        return;
      }
      dashboardManager.handleSelectDashboard(updatedDashboard);
      fetchAvailableQueries();
      setDashboards((prevDashboards) =>
        prevDashboards.map((d) =>
          d.id === updatedDashboard.id ? updatedDashboard : d
        )
      );
      if (selectedDashboard && selectedDashboard.id === updatedDashboard.id) {
        updateSelectedDashboard(updatedDashboard);
      }
    },
    [dashboardManager, fetchAvailableQueries, selectedDashboard, updateSelectedDashboard]
  );

  if (!authChecked) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-0 py-2 sm:py-4">
      {currentUser ? (
        <>
          <QuerySection
            onQueryResult={handleQueryResult}
            onQueryStart={handleQueryStart}
            queryResult={queryResult}
            isLoading={isLoading}
            currentUser={currentUser}
            onSaveQuery={handleSaveQuery}
            onAddToDashboard={handleAddToDashboard}
            onFollowUpQuery={handleFollowUpQuery}
            mainContentRef={mainContentRef}
            submitQuery={submitQuery}
            onDashboardUpdate={handleDashboardUpdate}
            dashboardManager={dashboardManager}
            isQueryJustAdded={isQueryJustAdded}
            setIsQueryJustAdded={setIsQueryJustAdded}
          />
          <div className="border-t border-gray-200 my-8"></div>
          <div className="mt-8 space-y-4">
            <DashboardList
              dashboards={dashboards}
              selectedDashboard={selectedDashboard}
              onSelectDashboard={dashboardManager.handleSelectDashboard}
              onNewDashboard={handleNewDashboard}
              onEditDashboard={handleEditDashboard}
              currentUser={currentUser}
              isAdmin={isAdmin}
            />
            <DashboardContent
              error={error}
              isDashboardLoading={isDashboardLoading}
              selectedDashboard={selectedDashboard}
              currentUser={currentUser}
              onSaveCardSettings={handleSaveCardSettings}
              onFollowUpQuery={handleFollowUpQuery}
              onSaveQuery={handleSaveQuery}
              onExplanationClick={handleExplanationClick}
            />
          </div>
          <DashboardModals
            isNewDashboardModalOpen={isNewDashboardModalOpen}
            setIsNewDashboardModalOpen={setIsNewDashboardModalOpen}
            newDashboardName={newDashboardName}
            setNewDashboardName={setNewDashboardName}
            selectedQueries={selectedQueries}
            setSelectedQueries={setSelectedQueries}
            handleCreateDashboard={handleCreateDashboard}
            currentUser={currentUser}
            availableQueries={availableQueries}
            isAddToDashboardModalOpen={isAddToDashboardModalOpen}
            setIsAddToDashboardModalOpen={setIsAddToDashboardModalOpen}
            dashboards={dashboards}
            handleAddQueryToDashboard={handleAddQueryToDashboard}
            handleNewDashboard={handleNewDashboard}
            isEditDashboardModalOpen={isEditDashboardModalOpen}
            setIsEditDashboardModalOpen={setIsEditDashboardModalOpen}
            selectedDashboard={selectedDashboard}
            handleUpdateDashboard={handleUpdateDashboard}
            handleDeleteDashboard={dashboardManager.handleDeleteDashboard}
            filterAvailableQueries={filterAvailableQueries}
            onDashboardUpdate={handleDashboardUpdate}
            isQueryJustAdded={isQueryJustAdded}
            setIsQueryJustAdded={setIsQueryJustAdded}
          />
        </>
      ) : (
        <div className="text-center py-10">
          <h2 className="text-2xl font-bold mb-4">Please log in to view this page</h2>
          <p>You need to be logged in to access the dashboard and query features.</p>
        </div>
      )}
    </div>
  );
};

export default Home;